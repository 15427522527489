"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateMessageService = void 0;
const tslib_1 = require("tslib");
const message_1 = require("../models/cloud-api/message");
const base_service_1 = require("./base.service");
class TemplateMessageService extends base_service_1.RestService {
    constructor() {
        super();
    }
    /**
     *
     * Reference: https://developers.facebook.com/docs/whatsapp/cloud-api/guides/send-message-templates#text-based
     *
     * @param message
     * @returns
     */
    sendTextTemplateMessage(message) {
        const _super = Object.create(null, {
            post: { get: () => super.post }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log('TemplateMessageService >> sendTextTemplateMessage', message);
            const config = this.getHeaderConfig(message.token);
            const url = this.getCloudAPIURL(message.phoneNumberID, 'messages');
            const parameters = message.params.map((param) => {
                return {
                    type: "text",
                    text: param
                };
            });
            const json = {
                messaging_product: "whatsapp",
                recipient_type: "individual",
                to: message.to,
                type: message_1.TypeMessageObjectEnum.template,
                template: {
                    name: message.template,
                    language: {
                        code: message.language || "en_US"
                    }
                }
            };
            if (!!parameters && parameters.length > 0) {
                const components = [
                    {
                        "type": "body",
                        "parameters": parameters
                    }
                ];
                if (json.template) {
                    json.template.components = components;
                }
            }
            return yield _super.post.call(this, url, json, config);
        });
    }
    /**
     *
     * Reference: https://developers.facebook.com/docs/whatsapp/cloud-api/guides/send-message-templates#media-based
     *
     * @param message
     * @returns
     */
    sendMediaTemplateMessage(message) {
        const _super = Object.create(null, {
            post: { get: () => super.post }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log('TemplateMessageService >> sendTextTemplateMessage', message);
            const config = this.getHeaderConfig(message.token);
            const url = this.getCloudAPIURL(message.phoneNumberID, 'messages');
            let headerComponent = {};
            let bodyComponent = {};
            const components = new Array();
            if (!!message.link && !!message.format) {
                const type = message.format;
                const jsonHeaderParam = { type };
                jsonHeaderParam[type] = {
                    link: message.link
                };
                headerComponent = {
                    type: "header",
                    parameters: [jsonHeaderParam]
                };
                components.push(headerComponent);
            }
            if (message.params.length > 0) {
                const parameters = message.params.map((param) => {
                    return {
                        type: "text",
                        text: param
                    };
                });
                bodyComponent = {
                    type: "body",
                    parameters: parameters
                };
                components.push(bodyComponent);
            }
            const json = {
                messaging_product: "whatsapp",
                to: message.to,
                type: message_1.TypeMessageObjectEnum.template,
                template: {
                    name: message.template,
                    language: {
                        code: message.language || "en_US"
                    },
                    components
                }
            };
            return yield _super.post.call(this, url, json, config);
        });
    }
}
exports.TemplateMessageService = TemplateMessageService;
