"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RestService = void 0;
const tslib_1 = require("tslib");
const axios_1 = require("axios");
const cloud_api_1 = require("../providers/cloud-api");
class RestService {
    constructor() { }
    getCloudAPIURL(id, suffix) {
        const base = cloud_api_1.cloudAPIProviders.CLOUD_API_URL;
        const version = cloud_api_1.cloudAPIProviders.CLOUD_API_VERSION;
        return `${base}/${version}/${id}/${suffix}`;
    }
    getBaseCloudAPIURL() {
        const base = cloud_api_1.cloudAPIProviders.CLOUD_API_URL;
        const version = cloud_api_1.cloudAPIProviders.CLOUD_API_VERSION;
        return `${base}/${version}`;
    }
    getHeaderConfig(token) {
        return {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
    }
    get(url, config) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log('url', JSON.stringify(url, null, 2));
            console.log('config', JSON.stringify(config, null, 2));
            return axios_1.default.get(url, config)
                .then((response) => {
                return (response.data);
            })
                .catch((error) => this.handleError(error));
        });
    }
    post(url, json, config) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log('url', JSON.stringify(url, null, 2));
            console.log('json', JSON.stringify(json, null, 2));
            console.log('config', JSON.stringify(config, null, 2));
            return axios_1.default.post(url, json, config)
                .then((response) => {
                console.log('response', response);
                return (response.data);
            })
                .catch((error) => this.handleError(error));
        });
    }
    handleError(err) {
        console.error('[trend-cloud-api] >> handleError >> ' + JSON.stringify(err));
        return Promise.resolve({ error: Object.assign({}, err) });
    }
}
exports.RestService = RestService;
