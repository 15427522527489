"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrendCloudAPIService = void 0;
const tslib_1 = require("tslib");
const message_service_1 = require("./services/message.service");
const phone_number_service_1 = require("./services/phone-number.service");
const template_message_service_1 = require("./services/template-message.service");
const template_service_1 = require("./services/template.service");
class TrendCloudAPIService {
    constructor() {
        // messaging 
        this.messageService = new message_service_1.MessageService();
        this.templateMessageService = new template_message_service_1.TemplateMessageService();
        this.phoneNumberService = new phone_number_service_1.PhoneNumberService();
        // templates
        this.templateService = new template_service_1.TemplateService();
    }
    /**
     * Build BaseRequest object
     *
     * @param token
     * @param phoneNumberID `
     * @param whatsAppBusinessAccountID
     * @returns
     */
    buildBaseRequest(token, phoneNumberID, whatsAppBusinessAccountID) {
        return {
            token,
            whatsAppBusinessAccountID,
            phoneNumberID
        };
    }
    /**
     * Send text free-form message
     *
     * @param message
     * @returns
     */
    sendTextMessage(message) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.messageService.sendTextMessage(message);
        });
    }
    /**
     * Send media free-form message
     *
     * @param message
     * @returns
     */
    sendMediaMessage(message) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.messageService.sendMediaMessage(message);
        });
    }
    /**
     * Send reaction free-form message
     *
     * @param message
     * @returns
     */
    sendReactionMessage(message) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.messageService.sendReactionMessage(message);
        });
    }
    /**
     * Send location free-form message
     *
     * @param message
     * @returns
     */
    sendLocationMessage(message) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.messageService.sendLocationMessage(message);
        });
    }
    /**
     * Update status to read
     *
     * @param message
     * @returns
     */
    updateStatus(message) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.messageService.updateStatus(message);
        });
    }
    /**
     * Get media - Retrieve Media URL
     *
     * @param message
     * @returns
     */
    getMediaById(message) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.messageService.getMediaById(message);
        });
    }
    /**
     * Get media - Retrieve Media URL as base64
     *
     * @param message
     * @returns
     */
    getDownloadByURL(message) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.messageService.getDownloadByURL(message);
        });
    }
    /**
     * Send text template message
     *
     * @param message
     * @returns
     */
    sendTextTemplateMessage(message) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.templateMessageService.sendTextTemplateMessage(message);
        });
    }
    /**
     * Send media template message
     *
     * @param message
     * @returns
     */
    sendMediaTemplateMessage(message) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.templateMessageService.sendMediaTemplateMessage(message);
        });
    }
    /**
     * Get all templates of whatsapp business account
     *
     * @param message
     * @returns
     */
    getTemplates(message) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.templateService.getTemplates(message);
        });
    }
    /**
     * Get all templates of whatsapp business account
     *
     * @param message
     * @returns
     */
    createTemplate(createTemplateRequest) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.templateService.createTemplate(createTemplateRequest);
        });
    }
    /**
     * Get all phone numbers of whatsapp business account
     *
     * @param message
     * @returns
     */
    getAllPhoneNumbers(baseRequest) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.phoneNumberService.getAllPhoneNumbers(baseRequest);
        });
    }
    /**
     * Get info of phone number
     *
     * @param message
     * @returns
     */
    getPhoneNumberIDInfo(baseRequest) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.phoneNumberService.getPhoneNumberIDInfo(baseRequest);
        });
    }
    /**
     * Get info of whatsapp business account
     *
     * @param message
     * @returns
     */
    getWhatsAppBusinessAccountInfo(baseRequest) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.phoneNumberService.getWhatsAppBusinessAccountInfo(baseRequest);
        });
    }
}
exports.TrendCloudAPIService = TrendCloudAPIService;
