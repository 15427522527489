"use strict";
/**
 * message.ds
 * Documentation: https://developers.facebook.com/docs/whatsapp/cloud-api/reference/messages
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TypeMessageObjectEnum = void 0;
var TypeMessageObjectEnum;
(function (TypeMessageObjectEnum) {
    TypeMessageObjectEnum["audio"] = "audio";
    TypeMessageObjectEnum["contacts"] = "contacts";
    TypeMessageObjectEnum["document"] = "document";
    TypeMessageObjectEnum["image"] = "image";
    TypeMessageObjectEnum["reaction"] = "reaction";
    TypeMessageObjectEnum["interactive"] = "interactive";
    TypeMessageObjectEnum["location"] = "location";
    TypeMessageObjectEnum["text"] = "text";
    TypeMessageObjectEnum["sticker"] = "sticker";
    TypeMessageObjectEnum["template"] = "template";
    TypeMessageObjectEnum["video"] = "video";
})(TypeMessageObjectEnum || (exports.TypeMessageObjectEnum = TypeMessageObjectEnum = {}));
