"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateService = void 0;
const tslib_1 = require("tslib");
const base_service_1 = require("./base.service");
class TemplateService extends base_service_1.RestService {
    constructor() {
        super();
    }
    getTemplates(templateRequest) {
        const _super = Object.create(null, {
            get: { get: () => super.get }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const config = this.getHeaderConfig(templateRequest.token);
            const url = this.getCloudAPIURL(templateRequest.whatsAppBusinessAccountID, 'message_templates');
            return yield _super.get.call(this, url, config);
        });
    }
    createTemplate(createTemplateRequest) {
        const _super = Object.create(null, {
            post: { get: () => super.post }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c;
            const config = this.getHeaderConfig(createTemplateRequest.token);
            const url = this.getCloudAPIURL(createTemplateRequest.phoneNumberID, 'message_templates');
            const json = {
                name: createTemplateRequest.name,
                language: createTemplateRequest.language,
                category: createTemplateRequest.category,
                components: new Array()
            };
            // {
            //   "type": "HEADER",
            //   "format": "TEXT",
            //   "text": "Our {{1}} is on!",
            //   "example": {
            //     "header_text": [
            //       "Summer Sale"
            //     ]
            //   }
            // },
            const createHeader = (headerText) => {
                return {
                    type: 'HEADER',
                    format: 'TEXT',
                    text: headerText,
                    example: {
                        "header_text": [
                            headerText
                        ]
                    }
                };
            };
            // {
            //   "type": "BODY",
            //   "text": "Shop now through {{1}} and use code {{2}} to get {{3}} off of all merchandise.",
            //   "example": {
            //     "body_text": [
            //       [
            //         "the end of August","25OFF","25%"
            //       ]
            //     ]
            //   }
            // },
            const createBody = (bodyText) => {
                return {
                    type: 'BODY',
                    text: bodyText,
                    example: {
                        "body_text": [
                            bodyText
                        ]
                    }
                };
            };
            // {
            //   "type": "FOOTER",
            //   "text": "Use the buttons below to manage your marketing subscriptions"
            // },
            const createFooter = (footerText) => {
                return {
                    type: 'FOOTER',
                    text: footerText
                };
            };
            //   "type":"BUTTONS",
            //   "buttons": [
            //     {
            //       "type": "QUICK_REPLY",
            //       "text": "Unsubscribe from Promos"
            //     },
            //     {
            //       "type":"QUICK_REPLY",
            //       "text": "Unsubscribe from All"
            //     }
            //   ]
            // }
            const createButtons = () => {
            };
            if (!!createTemplateRequest.headerText) {
                (_a = json.components) === null || _a === void 0 ? void 0 : _a.push(createHeader(createTemplateRequest.headerText));
            }
            if (!!createTemplateRequest.bodyText) {
                (_b = json.components) === null || _b === void 0 ? void 0 : _b.push(createBody(createTemplateRequest.bodyText));
            }
            if (!!createTemplateRequest.footerText) {
                (_c = json.components) === null || _c === void 0 ? void 0 : _c.push(createFooter(createTemplateRequest.footerText));
            }
            console.log(json);
            // TODO: add buttons 
            return yield _super.post.call(this, url, json, config);
        });
    }
}
exports.TemplateService = TemplateService;
